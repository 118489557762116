import { Pipe, PipeTransform } from '@angular/core';
import { SurveyQuestion } from '@dougs/core/config-back';

@Pipe({
  name: 'formatQuestionTitle',
  standalone: true,
})
export class FormatQuestionTitlePipe implements PipeTransform {
  transform(question: SurveyQuestion, option: string): string | undefined {
    switch (question.code) {
      case 'isEstimatedOverThreshold':
        return question.title?.replace('$threshold', option === 'productSale' ? '840 000€ HT' : '254 000€ HT');
      default:
        return question.title;
    }
  }
}
