import { Injectable } from '@angular/core';
import { combineLatest, from, Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { ConfigBackService, SurveyQuestion } from '@dougs/core/config-back';
import { FlashMessagesService } from '@dougs/ds';
import { SurveyStateService } from '@dougs/surveys/shared';
import { UserStateService } from '@dougs/user/shared';
import { SurveyService } from './survey.service';

@Injectable()
export class LabftSurveyService extends SurveyService {
  messageOnSuccess = 'Questionnaire LAB-FT signé';

  labftQuestions$ = this.configBackService.labftSurveyQuestions$;

  canSign$: Observable<boolean> = combineLatest([this.userStateService.loggedInUser$, this.labftQuestions$]).pipe(
    map(([loggedInUser, labftQuestions]) =>
      labftQuestions ? labftQuestions?.canSignFlags.some((flag) => loggedInUser.flags.includes(flag)) : false,
    ),
  );

  generateQuestionsWithoutRiskSeverity$: Observable<
    | {
        [key: string]: {
          title: string;
          questions: SurveyQuestion[];
        };
      }
    | undefined
  > = this.labftQuestions$.pipe(
    map((labftConfiguration) => {
      if (labftConfiguration) {
        return Object.keys(labftConfiguration.sections)
          .filter((key) => !key.includes('riskLevels'))
          .reduce(
            (obj, key) => ({
              ...obj,
              [key]: labftConfiguration.sections[key],
            }),
            {},
          );
      }
    }),
  );

  buildAndPopulateForm$ = this.labftQuestions$.pipe(
    tap((labftConfiguration) => {
      if (labftConfiguration) {
        const questions: SurveyQuestion[] = Object.values(labftConfiguration.sections)
          .map((section) => section.questions)
          .reduce((acc, cv) => acc.concat(cv), []);
        this.buildForm(questions);
      }
    }),
    concatMap(() => from(this.surveyStateService.getLabftSurvey(this.currentCompanyId))),
    tap((surveys) => {
      if (surveys && surveys.length > 0) {
        this.survey = surveys[0];
        super.populateForm(this.survey);
      }
    }),
  );

  constructor(
    protected readonly configBackService: ConfigBackService,
    protected readonly userStateService: UserStateService,
    protected readonly surveyStateService: SurveyStateService,
    protected readonly flashMessagesService: FlashMessagesService,
  ) {
    super(configBackService, userStateService, surveyStateService, flashMessagesService);
  }

  refreshAverageRisk(code: string, value: string | boolean, isFirstAccountingYear: boolean): void {
    switch (code) {
      case 'hasValidID':
      case 'hasPoliticalExposure':
      case 'hasPoliticalExposureDerogation':
      case 'hasSignificantFacts':
      case 'hasLegalsElements':
      case 'hasEffectiveBeneficiaries':
        this.selectAnswer('customerRisk', this.calculateCustomerRisk(), isFirstAccountingYear);
        break;
      case 'hasOthersActivities':
      case 'canCirculateLargeCashAmount':
      case 'canInvestIllegalActivityFund':
      case 'hasEcommerceActivity':
        this.selectAnswer('activityRisk', this.calculateActivityRisk(isFirstAccountingYear), isFirstAccountingYear);
        break;
      case 'hasOperationsWithDoubtCountries':
      case 'hasExternalFunds':
      case 'hasExternalFundsOrigin':
      case 'hasImportantFundsOriginValidation':
      case 'isRiskZoneLocated':
      case 'hasRiskZoneCeoNationality':
      case 'hasRiskZoneCeoAddress':
      case 'hasRiskZoneAssociateNationality':
      case 'hasRiskZoneAssociateAddress':
      case 'canReceiveFundFromRiskZone':
        this.selectAnswer('localizationRisk', this.calculateLocalizationRisk(), isFirstAccountingYear);
        break;
      case 'hasHighRiskMissions':
        this.selectAnswer('missionRisk', this.calculateMissionRisk(), isFirstAccountingYear);
        break;
      case 'hasComplexOperations':
        this.selectAnswer('complexOperationsRisk', this.calculateComplexOperationsRisk(), isFirstAccountingYear);
        break;
      case 'customerRisk':
      case 'activityRisk':
      case 'localizationRisk':
      case 'missionRisk':
      case 'complexOperationsRisk':
        this.selectAnswer('averageRisk', this.calculateAverageRisk(), isFirstAccountingYear);
        break;
      case 'averageRisk':
        this.selectAnswer('hasParticularMeasures', value === 'high', isFirstAccountingYear);
    }
  }

  private selectAnswer(code: string, value: string | boolean, isFirstAccountingYear: boolean): void {
    this.formGroup.get(code)?.setValue(value, { emitEvent: false, onlySelf: true });
    this.refreshAverageRisk(code, value, isFirstAccountingYear);
  }

  private calculateAverageRisk(): string {
    const risks = [
      this.formGroup.get('customerRisk')?.value,
      this.formGroup.get('activityRisk')?.value,
      this.formGroup.get('localizationRisk')?.value,
      this.formGroup.get('missionRisk')?.value,
      this.formGroup.get('complexOperationsRisk')?.value,
    ];

    return risks.includes('high') ? 'high' : risks.includes('medium') ? 'medium' : 'low';
  }

  private calculateComplexOperationsRisk(): string {
    return this.formGroup.get('hasComplexOperations')?.value === 'yes' ? 'high' : 'medium';
  }

  private calculateMissionRisk(): string {
    return this.formGroup.get('hasHighRiskMissions')?.value === 'yes' ? 'high' : 'medium';
  }

  private calculateCustomerRisk(): string {
    return this.formGroup.get('hasValidID')?.value === 'no' ||
      (this.formGroup.get('hasPoliticalExposure')?.value === 'yes' &&
        this.formGroup.get('hasPoliticalExposureDerogation')?.value !== 'yes') ||
      this.formGroup.get('hasSignificantFacts')?.value === 'yes' ||
      this.formGroup.get('hasLegalsElements')?.value === 'no' ||
      this.formGroup.get('hasEffectiveBeneficiaries')?.value === 'no'
      ? 'high'
      : 'medium';
  }

  private calculateActivityRisk(isFirstAccountingYear: boolean): string {
    return isFirstAccountingYear &&
      (this.formGroup.get('hasOthersActivities')?.value === 'yes' ||
        this.formGroup.get('canCirculateLargeCashAmount')?.value === 'yes' ||
        this.formGroup.get('canInvestIllegalActivityFund')?.value === 'yes' ||
        this.formGroup.get('hasEcommerceActivity')?.value === 'yes')
      ? 'high'
      : 'medium';
  }

  private calculateLocalizationRisk(): string {
    return this.formGroup.get('hasImportantFundsOriginValidation')?.value === 'no' ||
      this.formGroup.get('hasOperationsWithDoubtCountries')?.value === 'yes' ||
      (this.formGroup.get('hasExternalFunds')?.value === 'yes' &&
        this.formGroup.get('hasExternalFundsOrigin')?.value !== 'yes') ||
      this.formGroup.get('isRiskZoneLocated')?.value === 'yes' ||
      this.formGroup.get('hasRiskZoneCeoNationality')?.value === 'yes' ||
      this.formGroup.get('hasRiskZoneCeoAddress')?.value === 'yes' ||
      this.formGroup.get('hasRiskZoneAssociateNationality')?.value === 'yes' ||
      this.formGroup.get('hasRiskZoneAssociateAddress')?.value === 'yes' ||
      this.formGroup.get('canReceiveFundFromRiskZone')?.value === 'yes'
      ? 'high'
      : 'medium';
  }

  canEdit(question: SurveyQuestion): boolean {
    return (
      question.canEditFlags === undefined ||
      question.canEditFlags.some((flag) => this.userStateService.loggedInUser.flags.includes(flag))
    );
  }
}
