import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Survey } from '@dougs/surveys/dto';
import { SurveyHttpService } from '../http/survey.http';

interface SurveyState {
  surveys: Survey[];
}

@Injectable({
  providedIn: 'root',
})
export class SurveyStateService extends StateService<SurveyState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly surveyHttpService: SurveyHttpService,
  ) {
    super();
  }

  readonly surveysWithAttachments$: Observable<Survey[]> = this.select((state) =>
    state.surveys.filter((survey) => survey.attachments && survey.attachments.length > 0),
  );

  async refreshSurveys(
    companyId: number,
    options: {
      isSigned?: boolean;
      startDate: Date;
      endDate: Date;
      full?: boolean;
    },
  ): Promise<void> {
    try {
      this.setState({
        surveys: await lastValueFrom(this.surveyHttpService.getSurveys(companyId, options)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getMissionAcceptationSurvey(companyId: number): Promise<Survey[] | null> {
    try {
      return await lastValueFrom(
        this.surveyHttpService.getSurveys(companyId, { isSigned: false, type: 'missionAcceptation' }),
      );
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getLabftSurvey(companyId: number, isSigned = false): Promise<Survey[] | null> {
    try {
      return await lastValueFrom(this.surveyHttpService.getSurveys(companyId, { isSigned, type: 'labft' }));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateSurvey(survey: Survey): Promise<Survey | null> {
    try {
      const updatedSurvey: Survey = await lastValueFrom(this.surveyHttpService.updateSurvey(survey));

      this.setState({
        surveys:
          this.state?.surveys?.map((surveyIterated: Survey) =>
            surveyIterated.id === updatedSurvey.id ? updatedSurvey : surveyIterated,
          ) || [],
      });

      return updatedSurvey;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async signSurvey(survey: Survey, signProcess?: string): Promise<Survey | null> {
    try {
      const updatedSurvey: Survey = await lastValueFrom(this.surveyHttpService.signSurvey(survey, signProcess));

      this.setState({
        surveys: this.state.surveys.map((surveyIterated: Survey) =>
          surveyIterated.id === updatedSurvey.id ? updatedSurvey : surveyIterated,
        ),
      });
      return updatedSurvey;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }
}
