import { AsyncPipe, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { CompanyStateService } from '@dougs/company/shared';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  DividerComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  RadioComponent,
  RadioGroupComponent,
} from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { LabftSurveyService } from '../../services/labft-survey.service';

@Component({
  selector: 'dougs-labft-survey-modal',
  templateUrl: './labft-survey-modal.component.html',
  styleUrls: ['./labft-survey-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LabftSurveyService],
  standalone: true,
  imports: [
    NgIf,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    LabelFormFieldDirective,
    RadioGroupComponent,
    RadioComponent,
    DividerComponent,
    FormFieldComponent,
    ControlFormFieldDirective,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
    KeyValuePipe,
  ],
})
export class LabftSurveyModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      modifyOnly: boolean;
      accountingYear: AccountingYear;
      companyId?: number;
    },
    public readonly userStateService: UserStateService,
    public readonly labftSurveyService: LabftSurveyService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalRef: ModalRef,
  ) {
    this.labftSurveyService.currentCompanyId = this.data.companyId || this.companyStateService.activeCompany.id;
  }

  onChange(code: string, e: string | boolean): void {
    this.labftSurveyService.refreshAverageRisk(code, e, !this.data.accountingYear || this.data.accountingYear.isFirst);
  }

  async editAndSign(): Promise<void> {
    if (await this.labftSurveyService.editAndSign()) {
      this.modalRef.close();
    }
  }

  trackById(index: number): number {
    return index;
  }
}
