<ng-container *ngIf="missionAcceptationService.buildAndPopulateForm$ | async"></ng-container>
<ng-container *ngIf="userStateService.loggedInUser$ | async as user">
  <div dougsModalTitle>
    <h6>Questionnaire d'acceptation de la mission</h6>
    <i *ngIf="user.isAccountantOrAdmin" dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent [formGroup]="missionAcceptationService.formGroup">
    <div *ngFor="let question of missionAcceptationService.missionAcceptationQuestions$ | async; trackBy: trackByCode">
      <ng-container *ngIf="question.type === 'label'">
        <div class="flex-column mb-8">
          <label dougsFormFieldLabel>{{ question.title }}</label>
          <dougs-radio-group appearance="yesNo" [formControlName]="question.code">
            <dougs-radio *ngFor="let answer of question.availableAnswers" [value]="answer.value">
              {{ answer.label }}
            </dougs-radio>
          </dougs-radio-group>
        </div>
      </ng-container>
      <ng-container *ngIf="question.type === 'textarea'">
        <dougs-form-field>
          <label dougsFormFieldLabel>{{ question.title }}</label>
          <textarea dougsFormFieldControl [formControlName]="question.code"></textarea>
        </dougs-form-field>
      </ng-container>
      <dougs-divider class="mb-8" *ngIf="question.type === 'separator'"></dougs-divider>
    </div>
  </div>
  <div dougsModalFooter *ngIf="missionAcceptationService.canSignOrEdit$ | async">
    <dougs-button type="button" *ngIf="!data?.modifyOnly" (click)="editAndSign()">Signer ce questionnaire</dougs-button>
    <dougs-button type="button" *ngIf="data?.modifyOnly" (click)="missionAcceptationService.edit()">
      Enregistrer ce questionnaire
    </dougs-button>
  </div>
</ng-container>
