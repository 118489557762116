import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  AvatarMessageComponent,
  ButtonComponent,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { EntrySurveyService } from '../../services/entry-survey.service';
import { EntrySurveyModalQuestionComponent } from './entry-survey-question/entry-survey-question.component';

@Component({
  selector: 'dougs-entry-survey-modal',
  templateUrl: './entry-survey-modal.component.html',
  styleUrls: ['./entry-survey-modal.component.scss'],
  providers: [EntrySurveyService],
  standalone: true,
  imports: [
    ModalTitleDirective,
    NgIf,
    ModalCloseDirective,
    ModalContentDirective,
    LoaderComponent,
    AvatarMessageComponent,
    NgFor,
    EntrySurveyModalQuestionComponent,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class EntrySurveyModalComponent implements OnInit {
  constructor(
    @Inject(MODAL_DATA)
    public readonly data: {
      task: Task;
    },
    private readonly cdr: ChangeDetectorRef,
    public readonly modalRef: ModalRef,
    public readonly userStateService: UserStateService,
    public readonly entrySurveyService: EntrySurveyService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.entrySurveyService.pushPageViewed();
    await this.entrySurveyService.getTaskIfNotExist(this.data?.task);
    this.cdr.markForCheck();
  }

  async submit(): Promise<void> {
    await this.entrySurveyService.submit();
    this.modalRef.close(true);
  }
}
