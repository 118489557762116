import { Injectable } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { AccountingOnboarding } from '@dougs/accounting/onboarding/dto';
import { AccountingOnboardingStateService } from '@dougs/accounting/onboarding/shared';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ConfigBackService, SurveyQuestion, SurveyQuestionAnswer } from '@dougs/core/config-back';
import { MetricsService } from '@dougs/core/metrics';
import { OwnershipService } from '@dougs/core/ownership';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { SurveyCategory } from '@dougs/surveys/dto';
import { Task } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class EntrySurveyService {
  private task!: Task;
  private readonly taskSubject: BehaviorSubject<Task | undefined> = new BehaviorSubject<Task | undefined>(undefined);
  task$: Observable<Task | undefined> = this.taskSubject.asObservable();

  refreshAccountingOnboarding$ = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((activeCompany) => this.accountingOnboardingStateService.refreshAccountingOnboarding(activeCompany.id)),
  );

  questions$: Observable<SurveyQuestion[] | undefined> = combineLatest([
    this.configBackService.entrySurveyQuestions$,
    this.companyStateService.activeCompanyIdChanged$,
    this.accountingOnboardingStateService.accountingOnboarding$,
  ]).pipe(map(([questions, activeCompany, onboarding]) => this.filterQuestions(activeCompany, questions, onboarding)));

  categorizedQuestions$: Observable<SurveyCategory[] | undefined> = this.questions$.pipe(
    map((questions) => this.categorizeQuestions(questions)),
  );

  canSubmit$: Observable<boolean> = combineLatest([this.questions$, this.task$]).pipe(
    map(([questions, task]) => this.surveyHasInvalidValues(task, questions)),
  );

  readonly categoryLabels = {
    activity: 'Activité',
    specificStatus: 'Statuts spécifiques',
    onlineSell: 'Vente en ligne',
    location: 'Localisation',
    additionalInformations: 'Informations complémentaires',
  };

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly configBackService: ConfigBackService,
    private readonly userStateService: UserStateService,
    private readonly metricsService: MetricsService,
    private readonly accountingOnboardingStateService: AccountingOnboardingStateService,
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly ownershipService: OwnershipService,
    private readonly intercom: Intercom,
  ) {}

  async getTaskIfNotExist(task: Task | null): Promise<void> {
    if (!task) {
      const entrySurveyTasks: Task[] = await this.userTasksStateService.getUserTasks(
        this.companyStateService.activeCompany,
        'customer:entrySurvey',
      );
      this.task = entrySurveyTasks[0];
    } else {
      this.task = task;
    }
    this.task.metadata.answers = this.task.metadata.answers || {};

    this.taskSubject.next(this.task);
  }

  shouldShowQuestion(code?: string | null): boolean {
    const answers = this.task.metadata.answers;
    switch (code) {
      case 'behaviorReason':
        return this.task.metadata.behavior === 'alwaysCanActivateSubscription';
      default:
        return true;
    }
  }

  async submit(): Promise<void> {
    if (this.ownershipService.isMyCompany()) {
      this.metricsService.pushGAEvent('completed-entry-survey');
      this.metricsService.pushMixpanelEvent('Accounting Entrysurvey page submitted');
    }
    const calls = [this.userTasksStateService.updateTask(this.task)];
    await Promise.all(calls);
    await this.subscriptionStateService.refreshPlansPricing(this.companyStateService.activeCompany.id);
  }

  pushPageViewed(): void {
    if (this.ownershipService.isMyCompany()) {
      this.metricsService.pushPageview('/me/entry-survey');
      this.metricsService.pushMixpanelEvent('Accounting Entrysurvey page viewed');
    }
  }

  shouldShowInternalComment(question: SurveyQuestion): boolean {
    return !!(this.userStateService.loggedInUser.isAccountantOrAdmin && question?.internalComment);
  }

  private surveyHasInvalidValues(task?: Task, questions?: SurveyQuestion[]): boolean {
    if (!task) {
      return false;
    }

    const answers = task.metadata?.answers;
    if (!answers) {
      return false;
    }

    const hasInvalidValues = questions?.some(({ required, code }) => {
      return (
        required && this.shouldShowQuestion(code) && (!code || answers[code] === undefined || answers[code] === '')
      );
    });
    return !hasInvalidValues;
  }

  private categorizeQuestions(questions?: SurveyQuestion[]): SurveyCategory[] | undefined {
    if (questions) {
      const categorisedQuestionObject: { [category: string]: SurveyQuestion[] } = {};

      questions.forEach((question: SurveyQuestion) => {
        const category = question.category ?? 'default';

        if (categorisedQuestionObject[category] === undefined) {
          categorisedQuestionObject[category] = [];
        }
        categorisedQuestionObject[category].push(question);
      });

      return Object.keys(categorisedQuestionObject).map((category: string) => {
        return {
          category,
          questions: categorisedQuestionObject[category],
        };
      });
    }
  }

  private filterQuestions(
    activeCompany: Company,
    questions: SurveyQuestion[] | undefined,
    onboarding: AccountingOnboarding | null,
  ): SurveyQuestion[] | undefined {
    if (!questions) {
      return;
    }
    if (
      // hack: see monolith's userSignedUpBeforeEcommerceOptionsLimit
      //  (if already removed in monolith, remove this hard-coded, Date-based condition)
      new Date(this.userStateService.activeUser.createdAt) < new Date('2023-05-24T07:00:00.000Z') ||
      activeCompany.flags.includes('noPackOptions') ||
      onboarding?.data.packOptionsConfiguration?.ecommerce
    ) {
      return questions.filter((q) => !['ecommerceBasic'].includes(q.code || ''));
    }
    return questions;
  }

  updateTaskAnswer(code: string, value: unknown): void {
    if (this.task.metadata.answers) {
      // store question value
      this.task.metadata.answers[code] = value;
      // locally commit values
      this.taskSubject.next({ ...this.task });
    }
  }

  getQuestionSelectAnswers(question: SurveyQuestion): SurveyQuestionAnswer[] | undefined {
    if (!this.task || !this.task.metadata) {
      return [];
    }
    return question.availableAnswers;
  }

  getPreselectedAnswerForQuestion(question: SurveyQuestion): unknown | undefined {
    if (!question.code) {
      return undefined;
    }
    const failSafeTaskStorage = this.task.metadata?.answers || {};
    if (Object.prototype.hasOwnProperty.call(failSafeTaskStorage, question.code)) {
      return failSafeTaskStorage[question.code];
    }
    return undefined;
  }

  openIntercom(e: Event): void {
    e.preventDefault();
    this.intercom.show();
  }
}
