import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Survey } from '@dougs/surveys/dto';

@Injectable({
  providedIn: 'root',
})
export class SurveyHttpService {
  constructor(private readonly http: HttpClient) {}

  getSurveys(
    companyId: number,
    options: {
      isSigned?: boolean;
      startDate?: Date;
      endDate?: Date;
      full?: boolean;
      type?: string;
    },
  ): Observable<Survey[]> {
    let params: HttpParams = new HttpParams();

    if (options.isSigned !== undefined) {
      params = params.append('isSigned', options.isSigned);
    }

    if (options.full !== undefined) {
      params = params.append('full', options.full);
    }

    if (options.type) {
      params = params.append('type', options.type);
    }

    if (options.startDate) {
      params = params.append('startDate', options.startDate.toString());
    }

    if (options.endDate) {
      params = params.append('endDate', options.endDate.toString());
    }

    return this.http.get<Survey[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/surveys`, {
      params,
    });
  }

  updateSurvey(survey: Survey): Observable<Survey> {
    return this.http.post<Survey>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${survey.companyId}/surveys/${survey.id}`,
      survey,
    );
  }

  signSurvey(survey: Survey, signProcess?: string): Observable<Survey> {
    return this.http.post<Survey>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${survey.companyId}/surveys/${survey.id}/sign`,
      {
        context: { signProcess: signProcess || undefined },
      },
    );
  }
}
