<ng-container *ngIf="labftSurveyService.buildAndPopulateForm$ | async"></ng-container>
<ng-container *ngIf="userStateService.loggedInUser$ | async as user">
  <div dougsModalTitle>
    <h6>Questionnaire LAB-FT</h6>
    <i *ngIf="user.isAccountantOrAdmin" dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent [formGroup]="labftSurveyService.formGroup">
    <ng-container *ngIf="labftSurveyService.canSign$ | async">
      <h5 class="mb-24">{{ (labftSurveyService.labftQuestions$ | async)?.sections.riskLevels.title }}</h5>
      <ng-container
        *ngFor="
          let question of (labftSurveyService.labftQuestions$ | async)?.sections.riskLevels.questions;
          trackBy: trackById
        "
      >
        <ng-container *ngIf="!question.isHidden">
          <ng-container *ngIf="question.type === 'label'">
            <div class="flex-column mb-16">
              <label dougsFormFieldLabel>{{ question.title }}</label>
              <dougs-radio-group
                appearance="yesNo"
                [formControlName]="question.code"
                (ngModelChange)="onChange(question.code, $event)"
              >
                <dougs-radio
                  *ngFor="let answer of question.availableAnswers; trackBy: trackById"
                  [value]="answer.value"
                >
                  {{ answer.label }}
                </dougs-radio>
              </dougs-radio-group>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <dougs-divider class="mb-16"></dougs-divider>
    </ng-container>
    <div
      *ngFor="
        let section of labftSurveyService.generateQuestionsWithoutRiskSeverity$ | async | keyvalue;
        trackBy: trackById
      "
    >
      <h5 class="mb-24">{{ section.value.title }}</h5>
      <ng-container *ngFor="let question of section.value.questions; trackBy: trackById">
        <ng-container *ngIf="!question.isHidden">
          <ng-container *ngIf="question.type === 'label'">
            <div class="flex-column mb-16">
              <label dougsFormFieldLabel>{{ question.title }}</label>
              <dougs-radio-group
                appearance="yesNo"
                (ngModelChange)="onChange(question.code, $event)"
                [formControlName]="question.code"
              >
                <dougs-radio *ngFor="let answer of question.availableAnswers" [value]="answer.value">
                  {{ answer.label }}
                </dougs-radio>
              </dougs-radio-group>
            </div>
          </ng-container>
          <ng-container *ngIf="question.type === 'textarea'">
            <dougs-form-field>
              <label dougsFormFieldLabel>{{ question.title }}</label>
              <textarea dougsFormFieldControl [formControlName]="question.code"></textarea>
            </dougs-form-field>
          </ng-container>
        </ng-container>
      </ng-container>
      <dougs-divider class="mb-16"></dougs-divider>
    </div>
  </div>
  <div dougsModalFooter *ngIf="labftSurveyService.canSign$ | async">
    <dougs-button type="button" *ngIf="!data?.modifyOnly" (click)="editAndSign()">Signer ce questionnaire</dougs-button>
    <dougs-button type="button" *ngIf="data?.modifyOnly" (click)="labftSurveyService.edit()">
      Enregistrer ce questionnaire
    </dougs-button>
  </div>
</ng-container>
