import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfigBackService, SurveyQuestion } from '@dougs/core/config-back';
import { FlashMessagesService } from '@dougs/ds';
import { Survey } from '@dougs/surveys/dto';
import { SurveyStateService } from '@dougs/surveys/shared';
import { UserStateService } from '@dougs/user/shared';

export abstract class SurveyService {
  abstract messageOnSuccess: string;

  private _currentCompanyId!: number;

  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  survey: Survey | null = null;

  constructor(
    protected readonly configBackService: ConfigBackService,
    protected readonly userStateService: UserStateService,
    protected readonly surveyStateService: SurveyStateService,
    protected readonly flashMessagesService: FlashMessagesService,
  ) {}

  populateForm(survey: Survey): void {
    Object.entries(survey.form).forEach(([key, value]) => {
      this.formGroup.get(key)?.setValue(value);
    });
  }

  set currentCompanyId(companyId: number) {
    this._currentCompanyId = companyId;
  }

  get currentCompanyId(): number {
    return this._currentCompanyId;
  }

  async editAndSign(): Promise<boolean> {
    const updatedSurvey: Survey | null = await this.edit();
    if (updatedSurvey) {
      const signedSurvey: Survey | null = await this.surveyStateService.signSurvey(updatedSurvey);

      if (signedSurvey) {
        this.flashMessagesService.show(this.messageOnSuccess, {
          type: 'success',
          timeout: 5000,
        });

        return true;
      }
    }

    return false;
  }

  buildForm(questions: SurveyQuestion[]): void {
    this.formGroup = new UntypedFormGroup({});

    questions.forEach((question) => {
      this.addControl(question, !this.canEdit(question));
    });
  }

  addControl(question: SurveyQuestion, disabled: boolean): void {
    if (question.code && !question.isHidden) {
      this.formGroup.addControl(
        question.code,
        new UntypedFormControl({
          value: '',
          disabled,
        }),
      );
    }
  }

  async edit(): Promise<Survey | null> {
    if (this.survey) {
      return await this.surveyStateService.updateSurvey({
        ...this.survey,
        form: this.formGroup.value,
      });
    }

    return null;
  }

  abstract canEdit(question: SurveyQuestion): boolean;
}
