import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { SurveyQuestion, SurveyQuestionAnswer } from '@dougs/core/config-back';
import {
  ControlFormFieldDirective,
  RadioComponent,
  RadioGroupComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { Task, TaskMetadata } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { FormatQuestionTitlePipe } from '../../../pipes/format-question-title.pipe';
import { EntrySurveyService } from '../../../services/entry-survey.service';

@Component({
  selector: 'dougs-entry-survey-modal-question',
  templateUrl: './entry-survey-question.component.html',
  styleUrls: ['./entry-survey-question.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RadioGroupComponent,
    FormsModule,
    NgFor,
    RadioComponent,
    SelectComponent,
    ControlFormFieldDirective,
    SelectOptionComponent,
    AutosizeModule,
    FormatQuestionTitlePipe,
  ],
})
export class EntrySurveyModalQuestionComponent {
  shouldShowInternalComment = false;
  questionSelectAnswers: SurveyQuestionAnswer[] | undefined;
  showMoreInfos = false;

  private _task!: Task;

  @Input()
  set task(task: Task) {
    this._task = task;
    this.questionSelectAnswers = this.entrySurveyService.getQuestionSelectAnswers(this.question);
  }

  get task(): Task<TaskMetadata> {
    return this._task;
  }

  private _answer: unknown;

  @Input()
  set answer(answer: unknown) {
    this._answer = answer;
  }

  get answer(): unknown {
    return this._answer;
  }

  private _question!: SurveyQuestion;

  @Input()
  set question(question: SurveyQuestion) {
    this._question = question;
    this.shouldShowInternalComment = this.entrySurveyService.shouldShowInternalComment(question);
    this.questionSelectAnswers = this.entrySurveyService.getQuestionSelectAnswers(question);
  }

  get question(): SurveyQuestion {
    return this._question;
  }

  constructor(
    public readonly userStateService: UserStateService,
    public readonly entrySurveyService: EntrySurveyService,
  ) {}
}
