<div dougsModalTitle>
  <h6>Questionnaire d'entrée {{ (entrySurveyService.task$ | async)?.completedAt ? '(complété)' : '' }}</h6>
  @if (!modalRef.config?.disableClose) {
    <i [dougsModalClose]="null" class="fal fa-times"></i>
  }
</div>
<div dougsModalContent="large">
  @if (!(entrySurveyService.task$ | async)) {
    <dougs-loader></dougs-loader>
  }
  @if (entrySurveyService.task$ | async; as task) {
    <ng-container *ngIf="entrySurveyService.refreshAccountingOnboarding$ | async"></ng-container>
    @if (!task.completedAt) {
      <dougs-avatar-message
        class="mb-16"
        size="medium"
        fullNameTextSize="small"
        fullName="Sarah, Responsable Comptabilité"
        avatarUrl="https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg"
      >
        Bonjour,
        <br />
        Pour permettre de qualifier et compléter votre dossier, pouvez-vous répondre aux quelques questions
        suivantes&nbsp;? Cela ne prendra que quelques secondes. Merci&nbsp;!
      </dougs-avatar-message>
    }

    @for (categorisedQuestion of entrySurveyService.categorizedQuestions$ | async; track $index) {
      <div class="category-container pt-32">
        <h5 class="category-container-label">
          {{ entrySurveyService.categoryLabels[categorisedQuestion.category] ?? categorisedQuestion.category }}
        </h5>

        <div>
          @for (question of categorisedQuestion.questions; track $index) {
            @if (entrySurveyService.shouldShowQuestion(question.code)) {
              <dougs-entry-survey-modal-question
                class="mb-16"
                [question]="question"
                [task]="task"
                [answer]="entrySurveyService.getPreselectedAnswerForQuestion(question)"
              ></dougs-entry-survey-modal-question>
            }
          }
        </div>
      </div>
    }
  }
</div>
<div dougsModalFooter>
  <dougs-button (click)="submit()" [disabled]="!(entrySurveyService.canSubmit$ | async)">Terminer</dougs-button>
</div>
