import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { ConfigBackService } from '@dougs/core/config-back';
import { FlashMessagesService } from '@dougs/ds';
import { SurveyStateService } from '@dougs/surveys/shared';
import { UserStateService } from '@dougs/user/shared';
import { SurveyService } from './survey.service';

@Injectable()
export class MissionAcceptationService extends SurveyService {
  messageOnSuccess = "Questionnaire d'acceptation de mission signé";

  missionAcceptationQuestions$ = this.configBackService.missionAcceptationSurveyQuestions$;

  canSignOrEdit$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(map(() => this.canEdit()));

  buildAndPopulateForm$ = this.missionAcceptationQuestions$.pipe(
    tap((questions) => {
      if (questions) {
        this.buildForm(questions);
      }
    }),
    concatMap(() => from(this.surveyStateService.getMissionAcceptationSurvey(this.currentCompanyId))),
    tap((surveys) => {
      if (surveys && surveys.length > 0) {
        this.survey = surveys[0];
        this.populateForm(this.survey);
      }
    }),
  );

  constructor(
    protected readonly configBackService: ConfigBackService,
    protected readonly userStateService: UserStateService,
    protected readonly surveyStateService: SurveyStateService,
    protected readonly flashMessagesService: FlashMessagesService,
  ) {
    super(configBackService, userStateService, surveyStateService, flashMessagesService);
  }

  canEdit(): boolean {
    return this.userStateService.loggedInUser.flags.includes('role:charteredAccountant');
  }
}
