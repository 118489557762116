import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyStateService } from '@dougs/company/shared';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  DividerComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  RadioComponent,
  RadioGroupComponent,
} from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { MissionAcceptationService } from '../../services/mission-acceptation.service';

@Component({
  selector: 'dougs-mission-acceptation-modal',
  templateUrl: './mission-acceptation-modal.component.html',
  styleUrls: ['./mission-acceptation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MissionAcceptationService],
  standalone: true,
  imports: [
    NgIf,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    LabelFormFieldDirective,
    RadioGroupComponent,
    RadioComponent,
    FormFieldComponent,
    ControlFormFieldDirective,
    DividerComponent,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class MissionAcceptationModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      modifyOnly: boolean;
      companyId?: number;
    },
    public readonly userStateService: UserStateService,
    public readonly missionAcceptationService: MissionAcceptationService,
    private readonly modalRef: ModalRef,
    private readonly companyStateService: CompanyStateService,
  ) {
    this.missionAcceptationService.currentCompanyId = this.data?.companyId || this.companyStateService.activeCompany.id;
  }

  async editAndSign(): Promise<void> {
    if (await this.missionAcceptationService.editAndSign()) {
      this.modalRef.close();
    }
  }

  trackByCode(index: number): number {
    return index;
  }
}
