@if (question !== null) {
  <div class="question-container">
    <div class="question-container-label">
      <label>
        <p [innerHTML]="question | formatQuestionTitle: task.metadata.answers?.activityType"></p>
        @if (question.example) {
          <p class="tiny">
            {{ question.example }}
          </p>
        }
        @if (shouldShowInternalComment) {
          <p class="question-container-label__internalcomment color-admin tiny mt-16">
            {{ question.internalComment }}
          </p>
        }
      </label>
    </div>

    <div>
      @if (question.type === 'label') {
        <dougs-radio-group
          [ngModel]="answer"
          (ngModelChange)="entrySurveyService.updateTaskAnswer(question.code, $event)"
          appearance="yesNo"
        >
          @for (answer of question.availableAnswers; track $index) {
            <dougs-radio [value]="answer.value" [noDesign]="answer.value != true">{{ answer.label }}</dougs-radio>
          }
        </dougs-radio-group>
      }

      @if (question.type === 'select') {
        <dougs-select
          class="question__select"
          dougsFormFieldControl
          [ngModel]="answer"
          (ngModelChange)="entrySurveyService.updateTaskAnswer(question.code, $event)"
          placeholder="Sélectionner une valeur"
        >
          @for (option of questionSelectAnswers; track option.code) {
            <dougs-select-option [value]="option.code">
              {{ option.title }}
            </dougs-select-option>
          }
        </dougs-select>
      }

      @if (question.type === 'input') {
        <input
          type="text"
          [ngModel]="answer"
          (ngModelChange)="entrySurveyService.updateTaskAnswer(question.code, $event)"
          placeholder="Renseigner une valeur"
          dougsFormFieldControl
        />
      }

      @if (question.type === 'textarea') {
        <textarea
          type="text"
          [ngModel]="answer"
          (ngModelChange)="entrySurveyService.updateTaskAnswer(question.code, $event)"
          placeholder="Renseigner une valeur"
          dougsFormFieldControl
          autosize
        ></textarea>
      }
    </div>
  </div>
}
